.requestor-form {
  padding: 40px;
  text-align: left;
  max-width: 800px;
  color: black;
  background: white;
  box-shadow: 0px 11px 29px -11px black;
}

.bg-offwhite {
  background: #eee;
}

.requestor-form .form-group {
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 14px;
}

.form-group label {
  min-width: 200px;
}
